import { AnvsVector } from "../Core";

export namespace AnvsUpdatable {
    export interface FrameInfo {deltaTime: number, time: number};
}

export interface AnvsBounds {
    size: AnvsVector,
    offset: AnvsVector
} 

export interface AnvsBoundsGroup {
    canvas: {
        ratio: AnvsBounds,
        pixel: AnvsBounds,
    },
    relative: {
        ratio: AnvsBounds,
        pixel: AnvsBounds,
    }
}

export interface AnvsUpdatable<ToUpdate> {
    (p: ToUpdate, info:{bounds: AnvsBoundsGroup, frame: AnvsUpdatable.FrameInfo, state: any}): void
}