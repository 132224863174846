import { RanvsNumber } from "../Core/Primitive/Number";
import { isString } from "../../Help";
import { AnvsImage } from "./Image";
import { AnvsColor } from "./Primitive/Color";
import { Ranvs } from "./Ranvs";

import * as PIXI from 'pixi.js-legacy'

export interface AnvsText extends AnvsText.Properties {
    text: string,
}

export namespace AnvsText {

    export interface Properties {
        color?: AnvsColor.SimpleColor,
        blendMode?: AnvsImage.BlendModes,
        drawMode?: AnvsImage.DrawMode,
        fontSize?: number,
        fontWeight?: PIXI.TextStyleFontWeight,
        fontFamily?: string,
        outlineWidth?: number,
        outlineColor?: AnvsColor.SimpleColor,
    } 

    export type SimpleText = string | AnvsText; 

    export namespace SimpleText {

        export function toAnvsText(simple: SimpleText): AnvsText {
            if(isString(simple)) return {text: simple};
            else if(simple == null) return {text: ""};
            else return simple;
        }
    }

    export function defaultMissing(props: AnvsText, defaultProperties?: AnvsText): Required<AnvsText> {
        const def: Properties = {
            color: {r: 0, g: 0, b: 0, a: 255},
            blendMode: AnvsImage.GLBlendMode.NORMAL,
            drawMode: AnvsImage.DrawMode.Fit,
            fontSize: 30,
            fontWeight: "normal",
            fontFamily: "Arial",
            outlineColor: {r: 255, g: 255, b: 255, a: 255},
            outlineWidth: null
        };
        return {
            text: props?.text ?? defaultProperties?.text ?? "",
            color: AnvsColor.SimpleColor.toAnvsColor(props?.color) ?? AnvsColor.SimpleColor.toAnvsColor(defaultProperties?.color) ?? def.color,
            drawMode: props?.drawMode ?? defaultProperties?.drawMode ?? def.drawMode,
            blendMode: props?.blendMode ?? defaultProperties?.blendMode ?? def.blendMode,
            fontSize: props?.fontSize ?? defaultProperties?.fontSize ?? def.fontSize,
            fontFamily: props?.fontFamily ?? defaultProperties?.fontFamily ?? def.fontFamily,
            fontWeight: props?.fontWeight ?? defaultProperties?.fontWeight ?? def.fontWeight,
            outlineColor: AnvsColor.SimpleColor.toAnvsColor(props?.outlineColor) ?? AnvsColor.SimpleColor.toAnvsColor(defaultProperties?.outlineColor) ?? def.outlineColor,
            outlineWidth: props?.outlineWidth ?? defaultProperties?.outlineWidth ?? def.outlineWidth,
        }
    }

    export function copy(i: AnvsText): AnvsText {
        return i ? {
            text: i.text,
        } : null;
    }


}

export interface RanvsText extends RanvsText.Properties {
    text: Ranvs.Maybe.PoolOrOnceOrGet<string>,
}

export namespace RanvsText {

    export interface Properties {
        color?: Ranvs.Maybe.PoolOrOnceOrGet<AnvsColor.SimpleColor>,
        blendMode?: Ranvs.Maybe.PoolOrOnceOrGet<AnvsImage.BlendModes>,
        drawMode?: Ranvs.Maybe.PoolOrOnceOrGet<AnvsImage.DrawMode>,
        fontSize?: RanvsNumber,
        fontWeight?: Ranvs.Maybe.PoolOrOnceOrGet<PIXI.TextStyleFontWeight>,
        fontFamily?: Ranvs.Maybe.PoolOrOnceOrGet<string>,
        outlineColor?: Ranvs.Maybe.PoolOrOnceOrGet<AnvsColor.SimpleColor>,
        outlineWidth?: RanvsNumber,
    } 

    export type SimpleText = RanvsText | string;

    export function toText(rText: Ranvs.Maybe.PoolOrOnceOrGet<SimpleText>,  generator: Ranvs.Generator): Required<AnvsText> {

        rText = Ranvs.Maybe.Pool.pick(rText, generator);

        if(rText == null) return null;

        if(isString(rText)) rText = {text: rText};

        const text = Ranvs.Maybe.Pool.pick(rText.text, generator);
        const color = AnvsColor.SimpleColor.toAnvsColor(Ranvs.Maybe.Pool.pick(rText.color, generator));
        const drawMode = Ranvs.Maybe.Pool.pick(rText.drawMode, generator);
        const blendMode = Ranvs.Maybe.Pool.pick(rText.blendMode, generator);
        const fontSize = RanvsNumber.getNumber(rText.fontSize, generator);
        const fontFamily = Ranvs.Maybe.Pool.pick(rText.fontFamily, generator);
        const fontWeight = Ranvs.Maybe.Pool.pick(rText.fontWeight, generator);
        const outlineColor = AnvsColor.SimpleColor.toAnvsColor(Ranvs.Maybe.Pool.pick(rText.outlineColor, generator));
        const outlineWidth = RanvsNumber.getNumber(rText.outlineWidth, generator);

        return { text, color, drawMode, blendMode, fontSize, fontFamily, fontWeight, outlineColor, outlineWidth };
    }

}
