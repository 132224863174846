import { Ranvs } from "../Ranvs";

export type RanvsBoolean = Ranvs.Maybe.PoolOrOnceOrGet<boolean>;
export type RanvsNumber =  Ranvs.Maybe.PoolOrOnceOrGet<number | RanvsNumber.Range | RanvsNumber.Operator | Ranvs.Get<RanvsNumber, any>>;

export namespace RanvsNumber {

    export type AbstractRange = {min: RanvsNumber, max: RanvsNumber} | [RanvsNumber, RanvsNumber];

    export interface Range extends Ranvs.Cache<RanvsNumber> {
        range: AbstractRange,
        snap?: RanvsNumber,
        choose?: number,
    }

    export function isRanvsNumber(v: any): v is (Ranvs.Get<RanvsNumber, any> | Ranvs.Pool<RanvsNumber> | Ranvs.WeightedPool<RanvsNumber> | Range | Operator) {
        return Ranvs.isRanvs(v) || RanvsNumber.isOperator(v) || RanvsNumber.isRange(v);
    }

    export namespace Range {

        function _getRange(range: RanvsNumber.AbstractRange, snap: number, percent: number, generator: Ranvs.Generator) {
        
            var min = RanvsNumber.getNumber(range["min"] ?? range[0] ?? 0, generator)
            var max = RanvsNumber.getNumber(range["max"] ?? range[1] ?? 0, generator)
    
            var mag = Math.abs(min - max);
    
            var calculated = Math.min(min, max) + mag * percent;
            
            return snap ? Math.round(calculated / snap) * snap : calculated;
        }
    
        export function getRange(ranvsRange: Range,  generator: Ranvs.Generator): number {
            return _getRange(
                ranvsRange.range, 
                RanvsNumber.getNumber(ranvsRange.snap, generator), 
                ranvsRange.choose ?? (Math.abs(generator.generate()) % 100) / 100,
                generator
            );
        }
    }

    export type Operator = Operator.Add | Operator.Subtract | Operator.Multiply | Operator.Divide; 
    export namespace Operator {

        export interface Multiply extends Ranvs.Cache<number> {
            multiply: RanvsNumber[]
        }

        export interface Divide extends Ranvs.Cache<number>  {
            divide: RanvsNumber[]
        }

        export interface Add extends Ranvs.Cache<number>  {
            add: RanvsNumber[]
        }

        export interface Subtract extends Ranvs.Cache<number>  {
            subtract: RanvsNumber[]
        }

        export function getOperator(operator: Operator,  generator: Ranvs.Generator): number {

            var num: number = 0

            if(isMultiply(operator)) {
                num = 1;
                operator.multiply.forEach(element => {
                    num *= RanvsNumber.getNumber(element, generator);
                });
            } else if(isDivide(operator)) {
                num = RanvsNumber.getNumber(operator.divide[0], generator) ?? 0;
                for(var i = 1; i<operator.divide.length; i++) {
                    const element = operator.divide[i];
                    num /= RanvsNumber.getNumber(element, generator);
                }
            } else if(isAdd(operator)) {
                num = 0;
                operator.add.forEach(element => {
                    num += RanvsNumber.getNumber(element, generator);
                });
            } else if(isSubtract(operator)) {
                num = RanvsNumber.getNumber(operator.subtract[0], generator) ?? 0;
                for(var i = 1; i<operator.subtract.length; i++) {
                    const element = operator.subtract[i];
                    num -= RanvsNumber.getNumber(element, generator);
                }
            }

            return num;
        }

        export function isMultiply(object: RanvsNumber): object is Operator.Multiply {
            return object && typeof object === 'object' && 'multiply' in object;
        }

        export function isDivide(object: RanvsNumber): object is Operator.Divide {
            return object && typeof object === 'object' && 'divide' in object;
        }

        export function isAdd(object: RanvsNumber): object is Operator.Add {
            return object && typeof object === 'object' && 'add' in object;
        }

        export function isSubtract(object: RanvsNumber): object is Operator.Subtract {
            return object && typeof object === 'object' && 'subtract' in object;
        }

    }
    

    export function getNumber(ranvsNumber: RanvsNumber,  generator: Ranvs.Generator): number {

        return Ranvs.Maybe.cache(ranvsNumber, () => { 

            var value = (n: RanvsNumber): number => {
    
                if(isRange(n)) {
        
                    return Range.getRange(n, generator);
        
                } else if(isOperator(n)) {
        
                    return RanvsNumber.Operator.getOperator(n, generator);
        
                } else if (isRanvsNumber(n)) {
    
                    return RanvsNumber.getNumber(n, generator);
    
                }   else {
    
                    return n;
    
                }
    
            }

            ranvsNumber = Ranvs.Maybe.Pool.pickNoCache(ranvsNumber, generator);
            return value(ranvsNumber) 
        }, 
        generator.pass);
    }

    export function isOperator(object: RanvsNumber): object is RanvsNumber.Operator {
        return Operator.isAdd(object) || Operator.isSubtract(object) || Operator.isMultiply(object) || Operator.isDivide(object);
    }

    export function isRange(object: RanvsNumber): object is RanvsNumber.Range {
        return object && typeof object === 'object' && 'range' in object;
    }
        
}