import {BLEND_MODES, SCALE_MODES} from 'pixi.js-legacy'
import { isString } from "../../Help";
import { AnvsColorRGBA, AnvsColor, RanvsColor } from "./Primitive/Color";
import { Ranvs } from "./Ranvs";

type _ImageSource = string | null;

export interface AnvsImage extends AnvsImage.Properties {
    src: _ImageSource,
}

export namespace AnvsImage {

    export type SimpleImage = AnvsImage | string;

    export namespace SimpleImage {

        export function toAnvsImage(simple: SimpleImage): AnvsImage {
            if(isString(simple)) return {src: simple};
            else if(simple == null) return {src: null};
            else return simple;
        }
    }

    export enum DrawMode { Fit, Fill, Stretch }

    // NOTE: Reason for not including these as PIXI.SCALE_MODES directly is so that importing this file won't break ssr
    //       when all you want to do is a simple build using anvsreactlazy or something
    export enum FilteringMode { // PIXI.SCALE_MODES
        NEAREST = 0,
        LINEAR = 1
    }

    export type SimpleProcessorFunction = (rgba: AnvsColorRGBA) => AnvsColorRGBA;
    export type Processor = {needsReprocess?: boolean, id?: string, processor: SimpleProcessorFunction};

    export type BlendModes = CanvasBlendMode | GLBlendMode;

    export interface Properties {
        drawMode?: DrawMode,
        blendMode?: BlendModes,
        processors?: Processor[],
        tint?: AnvsColor.SimpleColor,
        filtering?: FilteringMode
    }

    export function defaultMissing(props: Properties, defaultProperties?: Properties): Required<Properties> {
        const def: Properties = {
            drawMode: DrawMode.Stretch,
            blendMode: CanvasBlendMode.NORMAL,
            processors: [],
            tint: 0xFFFFFFFF,
            filtering: FilteringMode.LINEAR
        };
        return {
            drawMode: props?.drawMode ?? defaultProperties?.drawMode ?? def.drawMode,
            blendMode: (props?.blendMode ?? defaultProperties?.blendMode ?? def.blendMode), // FIXME
            processors: props?.processors ?? defaultProperties?.processors ?? def.processors,
            tint: props?.tint ?? defaultProperties?.tint ?? def.tint,
            filtering: props?.filtering ?? defaultProperties?.filtering ?? def.filtering,
        }
    }

    export function copyProperties(i: Properties): Properties {
        return i ? {
            drawMode: i.drawMode,
            blendMode: i.blendMode,
            processors: i.processors,
            tint: i.tint ? AnvsColor.copy(i.tint) : null, 
            filtering: i.filtering
        } : null;
    }

    export function copy(i: AnvsImage): AnvsImage {
        return i ? {
            src: i.src,
            ...copyProperties(i)
        } : null;
    }

    export function equals(imageA: AnvsImage, imageB: AnvsImage): boolean {
        var a = {...defaultMissing(imageA), src: imageA?.src};
        var b = {...defaultMissing(imageB), src: imageB?.src};
        return a?.src == b?.src && a?.drawMode == b?.drawMode && a?.blendMode == b?.blendMode && AnvsColor.equals(a?.tint, b?.tint) && a?.filtering == b?.filtering;
    }

    // Canvas AnvsImage

    // Canvas supports all blend modes
    // export type CanvasBlendMode = BLEND_MODES;
    // export const CanvasBlendMode = BLEND_MODES;
    export enum CanvasBlendMode {
        NORMAL = 0,
        ADD = 1,
        MULTIPLY = 2,
        SCREEN = 3,
        OVERLAY = 4,
        DARKEN = 5,
        LIGHTEN = 6,
        COLOR_DODGE = 7,
        COLOR_BURN = 8,
        HARD_LIGHT = 9,
        SOFT_LIGHT = 10,
        DIFFERENCE = 11,
        EXCLUSION = 12,
        HUE = 13,
        SATURATION = 14,
        COLOR = 15,
        LUMINOSITY = 16,
        NORMAL_NPM = 17,
        ADD_NPM = 18,
        SCREEN_NPM = 19,
        NONE = 20,
        SRC_OVER = 0,
        SRC_IN = 21,
        SRC_OUT = 22,
        SRC_ATOP = 23,
        DST_OVER = 24,
        DST_IN = 25,
        DST_OUT = 26,
        DST_ATOP = 27,
        ERASE = 26,
        SUBTRACT = 28,
        XOR = 29
    }
    
    // NOTE: Only supported blend modes for GL are the following. See: https://api.pixijs.io/@pixi/constants/PIXI/BLEND_MODES.html
    export enum GLBlendMode {
        NORMAL = 0,//BLEND_MODES.NORMAL, 
        ADD = 1,//BLEND_MODES.ADD, 
        MULTIPLY = 2,// BLEND_MODES.MULTIPLY, 
        SCREEN = 3, //BLEND_MODES.SCREEN
    };

}

export interface RanvsImage extends RanvsImage.RanvsProperties {
    src: Ranvs.Maybe.PoolOrOnceOrGet<_ImageSource>,
}

export namespace RanvsImage {

    export type SimpleImage = RanvsImage | string;

    export interface RanvsProperties {
        drawMode?: Ranvs.Maybe.PoolOrOnceOrGet<AnvsImage.DrawMode>,
        blendMode?: Ranvs.Maybe.PoolOrOnceOrGet<AnvsImage.BlendModes>,
        processors?: Ranvs.Maybe.PoolOrOnceOrGet<AnvsImage.Processor[]>,
        tint?: Ranvs.Maybe.PoolOrOnceOrGet<RanvsColor>
        filtering?: AnvsImage.FilteringMode
    }

    export function toImage(image: Ranvs.Maybe.PoolOrOnceOrGet<SimpleImage>,  generator: Ranvs.Generator): AnvsImage {

        return Ranvs.Maybe.cache(image, ()=>{ 

            var i = Ranvs.Maybe.Pool.pickNoCache(image, generator);
    
            if(i == null) return null;
    
            if(isString(i)) return {src:i};
    
            const src = Ranvs.Maybe.Pool.pick(i.src, generator);
            const drawMode = Ranvs.Maybe.Pool.pick(i.drawMode, generator);
            const blendMode = Ranvs.Maybe.Pool.pick(i.blendMode, generator);
            const processors = Ranvs.Maybe.Pool.pick(i.processors, generator);
            const tint = RanvsColor.toColor(i.tint, generator);

            const filtering = i.filtering;
    
            return { src, drawMode, blendMode, processors, tint, filtering }
        }, generator.pass);
    }

}
