import React from "react"
import AnvsReactCanvas, { AnvsReactCanvasProps } from "./AnvsReactCanvas"
import AnvsReactGL, { AnvsReactGLProps } from "./AnvsReactGL"

export namespace AnvsReactLazy {
        
    const ssr = typeof window === "undefined"

    // https://www.gatsbyjs.com/docs/using-client-side-only-packages/
    const LazyCanvas = React.lazy(() =>
        import("./AnvsReactCanvas")
    )
    
    interface CanvasProps extends AnvsReactCanvasProps {
        placeholder?: Element
    }
    export class Canvas extends React.Component<CanvasProps, null> {
        constructor(props: CanvasProps) { super(props); }
        ref = React.createRef<AnvsReactCanvas>();
        get anvs() {
            return this.ref.current?.anvs
        }
        render() {
            return ssr ? null : <React.Suspense key="canvas_LAZY" fallback={this.props.placeholder ?? <div/>}>
                <LazyCanvas ref={this.ref} props={this.props} />
            </React.Suspense>
        }
    }

    const LazyGL = React.lazy(() =>
        import("./AnvsReactGL")
    )

    interface GLProps extends AnvsReactGLProps {
        placeholder?: Element
    }
    export class GL extends React.Component<GLProps, null> {
        constructor(props: GLProps) { super(props); }
        ref = React.createRef<AnvsReactGL>();
        get anvs() {
            return this.ref.current?.anvs
        }
        render() {
            return ssr ? null : <React.Suspense key="gl_LAZY" fallback={this.props.placeholder ?? <div/>}>
                <LazyGL ref={this.ref} {...this.props} />
            </React.Suspense>
        }
    }

}