import { Ranvs } from "../Ranvs";
import { RanvsNumber } from "./Number"

export interface AnvsVector {x: number, y: number}

export namespace AnvsVector {
    export type SimpleVector = AnvsVector | {x: number} | {y: number} | [number, number] | number
    export namespace SimpleVector {
        export function toAnvsVector(v: SimpleVector): AnvsVector { return v != null ? {x: v["x"] ?? v[0] ?? v ?? 0, y: v["y"] ?? v[1] ?? v ?? 0} : null; }
    }

    export function center() { return {x: 0.5, y: 0.5}; }
    export function one() { return  {x: 1, y: 1}; }
    export function zero() { return {x: 0, y: 0}; }
    export function scale(a: AnvsVector, scale: number) { return {x: a.x * scale, y: a.y * scale}; }
    export function add(a: AnvsVector, b: AnvsVector) { return {x: a.x + b.x, y: a.y + b.y}; }
    export function subtract(a: AnvsVector, b: AnvsVector) { return {x: a.x - b.x, y: a.y - b.y}; }
    export function multiply(a: AnvsVector, b: AnvsVector) { return {x: a.x * b.x, y: a.y * b.y}; }
    export function divide(a: AnvsVector, b: AnvsVector) { return {x: a.x / b.x, y: a.y / b.y}; }
    export function magnitude(a: AnvsVector) { return Math.sqrt((a.x * a.x) + (a.y * a.y)); }
    export function unit(a: AnvsVector) { return AnvsVector.scale(a, 1 / AnvsVector.magnitude(a)); }
    export function equals(a: AnvsVector, b: AnvsVector) { return a.x === b.x && a.y === b.y; }
    export function copy(v: AnvsVector) { return {x: v.x, y: v.y}; }
}

export type RanvsVector = Ranvs.Maybe.PoolOrOnceOrGet<{x: RanvsNumber, y: RanvsNumber} | {x: RanvsNumber} | {y: RanvsNumber} | [RanvsNumber, RanvsNumber] | RanvsNumber>

export namespace RanvsVector {

    export type SimpleVector = {x: RanvsNumber, y: RanvsNumber} | {x: RanvsNumber} | {y: RanvsNumber} | [RanvsNumber, RanvsNumber] | RanvsNumber

    export function toAnvsVector(v: RanvsVector, defaultV: AnvsVector,  generator: Ranvs.Generator): AnvsVector {

        var getVector = (v: SimpleVector): AnvsVector => { 

            if(v == null) return null;
    
            let x: RanvsNumber = v["x"]; // ?? v[0] ?? v ?? 0;
            let y: RanvsNumber = v["y"]; // ?? v[1] ?? v ?? 0;

            if(x == null && y == null) {
                x = v[0];
                y = v[1];
            } else if(y == null) {
                y = defaultV.y;
            } else if(x == null) {
                x = defaultV.x;
            }

            var finalX;
            var finalY;

            if(x == null && y == null) {
                x = v;
                finalX = RanvsNumber.getNumber(x, generator);
                finalY = finalX;
            }
            
            const final = {
                x: finalX ?? RanvsNumber.getNumber(x ?? defaultV.x, generator), 
                y: finalY ?? RanvsNumber.getNumber(y ?? defaultV.y, generator)
            }

            return final;
        }

        return Ranvs.Maybe.cache(v, () => {
            var maybeOnce = Ranvs.Maybe.Pool.pickNoCache(v, generator);
            return getVector(maybeOnce);
        }, 
        generator.pass);
        
    }
    
}

// TODO: make size and stuff independent

export type AnvsCanvasNumberPixel = {pixel: number}
export type AnvsCanvasNumberRelativeParent = {parent: number}
export type AnvsCanvasNumberRelativeCanvas = {canvas: number}
export type AnvsCanvasNumber = AnvsCanvasNumberPixel | AnvsCanvasNumberRelativeCanvas | AnvsCanvasNumberRelativeParent

export namespace AnvsCanvasNumber {

    export function getNumber(number: AnvsCanvasNumber): number { return isPixel(number) ? number.pixel : isRelativeCanvas(number) ? number.canvas : number.parent}

    export function isPixel(object: AnvsCanvasNumber): object is AnvsCanvasNumberPixel {
        return object && typeof object === 'object' && 'pixel' in object;
    }

    export function isRelativeParent(object: AnvsCanvasNumber): object is AnvsCanvasNumberRelativeParent {
        return object && typeof object === 'object' && 'parent' in object;
    }

    export function isRelativeCanvas(object: AnvsCanvasNumber): object is AnvsCanvasNumberRelativeCanvas {
        return object && typeof object === 'object' && 'canvas' in object;
    }
}

export type RanvsCanvasNumberPixel = {pixel: RanvsNumber}
export type RanvsCanvasNumberRelativeParent = {parent: RanvsNumber}
export type RanvsCanvasNumberRelativeCanvas = {canvas: RanvsNumber}
export type RanvsCanvasNumber = RanvsCanvasNumberPixel | RanvsCanvasNumberRelativeCanvas | RanvsCanvasNumberRelativeParent

export namespace RanvsCanvasNumber {

    export function getNumber(number: RanvsCanvasNumber): RanvsNumber { return isPixel(number) ? number.pixel : isRelativeCanvas(number) ? number.canvas : number.parent}

    export function isPixel(object: RanvsCanvasNumber): object is RanvsCanvasNumberPixel {
        return object && typeof object === 'object' && 'pixel' in object;
    }

    export function isRelativeParent(object: RanvsCanvasNumber): object is RanvsCanvasNumberRelativeParent {
        return object && typeof object === 'object' && 'parent' in object;
    }

    export function isRelativeCanvas(object: RanvsCanvasNumber): object is RanvsCanvasNumberRelativeCanvas {
        return object && typeof object === 'object' && 'canvas' in object;
    }

    export function toAnvsCanvasNumber(n: RanvsCanvasNumber, generator: Ranvs.Generator): AnvsCanvasNumber {
        const ranvs = getNumber(n)
        const num = RanvsNumber.getNumber(ranvs, generator)
        return isPixel(n) ? {pixel: num} : isRelativeCanvas(n) ? {canvas: num} : {parent: num}
    }
}

export type AnvsCanvasVector = {
    x: AnvsCanvasNumber,
    y: AnvsCanvasNumber,
}

export namespace AnvsCanvasVector {
    export type SimpleCanvasVector = AnvsCanvasVector | {x: AnvsCanvasNumber,} | {y: AnvsCanvasNumber,} | 
        {pixel: AnvsVector.SimpleVector} | {parent: AnvsVector.SimpleVector} | {canvas: AnvsVector.SimpleVector} | AnvsVector.SimpleVector

    function hasPixel(object: SimpleCanvasVector): object is {pixel: AnvsVector.SimpleVector}  {
        return object && typeof object === 'object' && 'pixel' in object;
    }

    function hasRelativeParent(object: SimpleCanvasVector): object is {parent: AnvsVector.SimpleVector} {
        return object && typeof object === 'object' && 'parent' in object;
    }

    function hasRelativeCanvas(object: SimpleCanvasVector): object is {canvas: AnvsVector.SimpleVector} {
        return object && typeof object === 'object' && 'canvas' in object;
    }
    export function toAnvsVector(v: SimpleCanvasVector): AnvsCanvasVector { 
        
        return v != null ? {x: v["x"] ?? v[0] ?? v ?? 0, y: v["y"] ?? v[1] ?? v ?? 0} : null; 
    }
}

export type RanvsCanvasVector = {
    x: RanvsCanvasNumber,
    y: RanvsCanvasNumber,
}
export namespace RanvsCanvasVector {
    export type SimpleCanvasVector = RanvsCanvasVector | {x: RanvsCanvasNumber,} | {y: RanvsCanvasNumber,} | 
        {pixel: RanvsVector.SimpleVector} | {parent: RanvsVector.SimpleVector} | {canvas: RanvsVector.SimpleVector} | RanvsVector.SimpleVector

    export function toAnvsVector(v: AnvsCanvasVector): AnvsVector { return v != null ? {x: v["x"] ?? v[0] ?? v ?? 0, y: v["y"] ?? v[1] ?? v ?? 0} : null; }
}
