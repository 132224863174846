import { isNumber, isString } from "../../../Help"
import { Ranvs } from "../Ranvs"
import { RanvsNumber } from "./Number"

import { AnvsColorString, CssColors } from "./CssColor"

export type AnvsColor = {r:number, g:number, b:number, a?: number}
export type AnvsColorRGBA = Required<AnvsColor>
export type AnvsColorHSV = {h:number, s:number, v:number}

const componentToHex = (c: number) => {
    var hex = c.toString(16);
    return hex.length === 1 ? "0" + hex : hex;
}

export namespace AnvsColor {

    export const clear = {r:0, g:0, b:0, a:0}
    export const white = {r:255, g:255, b:255, a:255}
    export const black = {r:255, g:255, b:255, a:255}

    // You must specify all 8 rgba digits if using a number otherwise it'll be the incorrect color. We fill the number strinng with zeros, this is under the 
    // assumption that all digits are included in the number...

    // Example: 0 x 00 00 00 01 should be black with alpha of 1/255, but this just becomes "1"... how do we know if this is 0x000001(FF) or 0x00000001?
    // This same issue arrises with other hex combinations, but by making the convention be that you put all 8 digits, we can fill the shortened js hex
    // string with zeros and have a correct value 100% of the time as long as this is followed... perhaps just remove number support altogether? Idk, whatever

    export type SimpleColor = AnvsColor | string | number | AnvsColorString

    export namespace SimpleColor {
        export function toAnvsColor(simple: SimpleColor): AnvsColorRGBA {
            if(isNumber(simple)) {
                return AnvsColor.fromHex(simple);
            }else if(isString(simple)) {
                if(CssColors[simple as AnvsColorString] != null) {
                    simple = CssColors[simple as AnvsColorString];
                }
                return AnvsColor.fromHexString(simple);
            }else {
                return {r: simple?.r ?? 255, g: simple?.g ?? 255, b: simple?.b ?? 255, a: simple?.a ?? 255}
            }
        }
    }

    export function equals(A: AnvsColor.SimpleColor, B: AnvsColor.SimpleColor): boolean {
        const a = SimpleColor.toAnvsColor(A);
        const b = SimpleColor.toAnvsColor(B);
        return a.r == b.r && a.g == b.g && a.b == b.b && a.a == b.a;
    }

    export function copy(simple: SimpleColor): AnvsColorRGBA {
        return SimpleColor.toAnvsColor(simple); // this is a copy itself so this is all we need.
    }
    
    export function toHexString (rgba: AnvsColor, hash?: boolean): string {
        hash = hash ?? true
        return (hash ? "#" : "0x") + componentToHex(rgba.r) + componentToHex(rgba.g) + componentToHex(rgba.b) + (rgba.a ? componentToHex(rgba.a) : "");
    }

    export function toHex(rgba: AnvsColor): number {
        return parseInt(AnvsColor.toHexString(rgba).replace(/\W/g, ''), 16);
    }

    export function fromHexString(hex: string): AnvsColorRGBA {
        hex = hex.replace("0x","").replace("#","")
        var result = /([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})?$/i.exec(hex);
        return result ? {
          r: parseInt(result[1], 16),
          g: parseInt(result[2], 16),
          b: parseInt(result[3], 16),
          a: result[4] ? parseInt(result[4], 16) : 255,
        } : null;
    }

    export function fromHex(hex: number): AnvsColorRGBA {
        var str = hex.toString(16);
        while(str.length < 8) {
            str = "0" + str;
        }
        return AnvsColor.fromHexString(str)
    }
    
    export function isRGB(object: any): object is AnvsColorRGBA {
        return object && typeof object === 'object' && 'r' in object && 'g' in object && 'b' in object;
    }

    export function isRGBA(object: any): object is AnvsColorRGBA {
        return object && typeof object === 'object' && 'r' in object && 'g' in object && 'b' in object && 'a' in object;
    }

    export function RGBtoHSV(color: AnvsColor): AnvsColorHSV {
        // input: r,g,b in [0,1], out: h in [0,360) and s,v in [0,1]
        var {r,g,b} = color;
        r/=255, g/=255, b/=255;
        let v = Math.max(r,g,b), c = v - Math.min(r,g,b);
        let h = c && ((v==r) ? (g-b) / c : ((v==g) ? 2+(b-r) / c : 4+(r-g) / c)); 
        return {h: 60*(h<0?h+6:h), s: v&&c/v, v};
    }

    export function HSVtoRGB(color: AnvsColorHSV) {  
        // input: h in [0,360] and s,v in [0,1] - output: r,g,b in [0,1]
        const {h,s,v} = color;                            
        let f = (n: number, k=(n+h/60)%6) => v - v*s*Math.max( Math.min(k,4-k,1), 0);     
        return {r: Math.round(f(5) * 255), g: Math.round(f(3) * 255), b: Math.round(f(1) * 255)};       
    }   
}

interface _RanvsColor extends Ranvs.Cache<AnvsColor> {
    color: AnvsColor.SimpleColor,
    hue?: RanvsNumber, // degrees
    brightness?: RanvsNumber, // 0..1
    saturation?: RanvsNumber, // 0..1
    alpha?: RanvsNumber, // 0..1
}

export type RanvsColor = _RanvsColor | AnvsColor.SimpleColor;

export namespace RanvsColor {

    function isRanvsColor(object: any): object is _RanvsColor {
        return object && typeof object === 'object' && 'color' in object;
    }

    export function toColor(color: Ranvs.Maybe.PoolOrOnceOrGet<RanvsColor>,  generator: Ranvs.Generator): AnvsColorRGBA {

        return Ranvs.Maybe.cache(color, ()=>{ 
            let aColor: AnvsColorRGBA;
            const rColor = Ranvs.Maybe.Pool.pickNoCache(color, generator);
            if(isRanvsColor(rColor)) {
                var hsv = AnvsColor.RGBtoHSV(AnvsColor.SimpleColor.toAnvsColor(rColor.color));
                hsv.h = hsv.h + (RanvsNumber.getNumber(rColor.hue, generator) ?? 0);
                hsv.s = RanvsNumber.getNumber(rColor.saturation, generator) ?? hsv.s;
                hsv.v = RanvsNumber.getNumber(rColor.brightness, generator) ?? hsv.v;
                aColor = {...AnvsColor.HSVtoRGB(hsv), a: (RanvsNumber.getNumber(rColor.alpha, generator) ?? 1) * 255};
            } else {
                aColor = AnvsColor.SimpleColor.toAnvsColor(rColor);
            }
            return aColor;
        }, generator.pass);
    }

}
