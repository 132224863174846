import { AnvsImage } from "./Image"
import { Ranvs } from "./Ranvs"
import { RanvsNumber } from "./Primitives"
import { AnvsUpdatable } from "./Updatable"
import * as PIXI from 'pixi.js-legacy'

type Vec4Mat2 = [number, number, number, number]
type Mat3 = [number, number, number, number, number, number, number, number, number]
type Mat4 = [number, number, number, number, number, number, number, number, number, number, number, number,number, number, number, number]

// A note on shader blend mode vs sprite blend mode:
// https://github.com/pixijs/pixijs/issues/7224#issuecomment-773708685

// In general, for filters to work, Pixi does a "snapshot" of your display object to another surface then applies 
// the filters to that. Because blend modes are a post-process, when you use multiply to your original container of 
// sprites Pixi doesn't know what to multiply the pixels to when it does the snapshot because blend modes take into 
// account the pixels below the object. What you actually need to do is add the blend-mode to is the filter, which 
// will apply it to the snapshot of the original source.

interface BaseAnvsShader {
    id?: string,
    blendMode?: AnvsImage.GLBlendMode,
    update?: AnvsShader.Updater,
}

export interface AnvsCustomShader extends BaseAnvsShader {
    fragment?: string,
    vertex?: string,
    uniforms?: AnvsShader.UniformGroup,
}

export interface AnvsPixiFilter extends BaseAnvsShader {id?: string, pixi: PIXI.Filter}

export namespace AnvsPixiFilter {
    // export function mergeUniforms(anvsPixiFilter: AnvsPixiFilter) {
    //     anvsPixiFilter.uniforms = anvsPixiFilter.uniforms ?? {};
    //     Object.keys(anvsPixiFilter.pixi.uniforms).forEach(uKey => {
    //         anvsPixiFilter.uniforms[uKey] = {type: AnvsShader.Uniform.Type.u_any, value: anvsPixiFilter.pixi.uniforms[uKey]}
    //     });
    // }
}

export type AnvsShader = AnvsCustomShader | AnvsPixiFilter

export namespace AnvsShader {

    // Kinda dumb... could probably just support having pixi filters or anvsshaders, but idc atm.
    // Also doesn't seem to work properly, probably stuff under the hood?
    // function fromPixi(filter: PIXI.Filter, options?: {update?: AnvsShader.Updater, blendMode?: AnvsImage.GLImage.BlendMode}) {
    //     const {update, blendMode} = options ?? {};

    //     var fragment = filter.program.fragmentSrc;
    //     var vertex = filter.program.vertexSrc;

    //     var uniforms: AnvsShader.UniformGroup = {} 
    //     Object.keys(filter.uniforms).forEach(uKey => {
    //         uniforms[uKey] = {type: Uniform.Type.u_any, value: filter.uniforms[uKey]}
    //     });

    //     return { uniforms, fragment, vertex, update, blendMode };
    // }


    export function isPixi(s: AnvsShader | RanvsShader): s is AnvsPixiFilter {
        return s && typeof s === 'object' && 'pixi' in s;
    }

    export interface Uniform {
        type: AnvsShader.Uniform.Type,
        value: any
    }

    export interface NumberUniform extends Omit<Uniform, "type" | "value"> {
        type: Uniform.Type.u_float | Uniform.Type.u_int,
        value: number
    }

    export namespace NumberUniform {
        export function isNumber(u: Uniform): u is NumberUniform {
            return u && typeof u === 'object' && (u.type == Uniform.Type.u_int || u.type == Uniform.Type.u_float);
        }
    }

    export type UniformGroup = {[key in string]: AnvsShader.Uniform}

    export type Updater = (u: UniformGroup, info: AnvsUpdatable.FrameInfo) => UniformGroup;

    export namespace Uniform {

        // Some basic helpers
        export function fromString(s: AnvsShader.Uniform.Type.String, value: any): AnvsShader.Uniform {
            return {type: AnvsShader.Uniform.Type.fromString(s), value}
        }

        export function bool(value: boolean): AnvsShader.Uniform {
            return {type: AnvsShader.Uniform.Type.u_bool, value}
        }
        export function int(value: number): AnvsShader.Uniform {
            return {type: AnvsShader.Uniform.Type.u_int, value}
        }
        export function float(value: number): AnvsShader.Uniform {
            return {type: AnvsShader.Uniform.Type.u_float, value}
        }
        export function float2(value: [number, number]): AnvsShader.Uniform {
            return {type: AnvsShader.Uniform.Type.u_2f, value}
        }
        export function float3(value: [number, number, number]): AnvsShader.Uniform {
            return {type: AnvsShader.Uniform.Type.u_3f, value}
        }
        export function float4(value: Vec4Mat2): AnvsShader.Uniform {
            return {type: AnvsShader.Uniform.Type.u_4f, value}
        }
        export function vec2(value: {x: number, y: number}): AnvsShader.Uniform {
            return {type: AnvsShader.Uniform.Type.u_vec2, value}
        }
        export function vec3(value: {x: number, y: number, z: number}): AnvsShader.Uniform {
            return {type: AnvsShader.Uniform.Type.u_vec3, value}
        }
        export function vec4(value: {x: number, y: number, z: number, w: number}): AnvsShader.Uniform {
            return {type: AnvsShader.Uniform.Type.u_vec4, value}
        }
        export function mat2(value: Vec4Mat2): AnvsShader.Uniform {
            return {type: AnvsShader.Uniform.Type.u_mat2, value}
        }
        export function mat3(value: Mat3): AnvsShader.Uniform {
            return {type: AnvsShader.Uniform.Type.u_mat3, value}
        }
        export function mat4(value: Mat4): AnvsShader.Uniform {
            return {type: AnvsShader.Uniform.Type.u_mat4, value}
        }
        
        export enum Type {
            u_bool = 'bool',
            // single int value
            u_int = 'i',
            // single float value
            u_float = 'f',
            // Float32Array(2) or JS Arrray
            u_2f = '2f',
            // Float32Array(3) or JS Arrray
            u_3f = '3f',
            // Float32Array(4) or JS Arrray
            u_4f = '4f',
            // a 2D Point object
            u_vec2 = 'v2',
            // a 3D Point object
            u_vec3 = 'v3',
            // a 4D Point object
            u_vec4 = 'v4',
            // Int32Array or JS Array
            u_1iv = '1iv',
            // Int32Array or JS Array
            u_2iv = '2iv',
            // Int32Array or JS Array
            u_3iv = '3iv',
            // Int32Array or JS Array
            u_4iv = '4iv',
            // Float32Array or JS Array
            u_1fv = '1fv',
            // Float32Array or JS Array
            u_2fv = '2fv',
            // Float32Array or JS Array
            u_3fv = '3fv',
            // Float32Array or JS Array
            u_4fv = '4fv',
            // Float32Array or JS Array
            u_mat2 = 'mat2',
            // Float32Array or JS Array
            u_mat3 = 'mat3',
            // Float32Array or JS Array
            u_mat4 = 'mat4',
            // a Color Value
            u_c = 'c',
            // flat array of integers (JS or typed array)
            u_iv1 = 'iv1',
            // flat array of integers with 3 x N size (JS or typed array)
            u_iv = 'iv',
            // flat array of floats (JS or typed array)
            u_fv1 = 'fv1',
            // flat array of floats with 3 x N size (JS or typed array)
            u_fv = 'fv',
            // array of 2D Point objects
            u_v2v = 'v2v',
            // array of 3D Point objects
            u_v3v = 'v3v',
            // array of 4D Point objects
            u_v4v = 'v4v',
            // let pixi figure it out
            u_any = '',
        }

        export namespace Type {

            export type String = 
                'b'| 'bool'| 'boolean'|
                'i'| '1i'|
                'f'| '1f'|
                '2f'|
                '3f'|
                '4f'|
                'v2'|
                'v3'|
                'v4'|
                '1iv'|
                '2iv'|
                '3iv'|
                '4iv'|
                '1fv'|
                '2fv'|
                '3fv'|
                '4fv'|
                'm2'| 'mat2'| 'Matrix2fv'|
                'm3'| 'mat3'| 'Matrix3fv'|
                'm4'| 'mat4'| 'Matrix4fv'|
                'c'|
                'iv1'|
                'iv'|
                'fv1'|
                'fv'|
                'v2v'|
                'v3v'|
                'v4v';

            export function toString(t: AnvsShader.Uniform.Type): string {
                return t as string;
            }

            export function fromString(s: AnvsShader.Uniform.Type.String): AnvsShader.Uniform.Type {
                switch(s) {
                    case 'b':
                    case 'bool':
                    case 'boolean':
                        return AnvsShader.Uniform.Type.u_bool
                    case 'i':
                    case '1i':
                        return AnvsShader.Uniform.Type.u_int
                    case 'f':
                    case '1f':
                        return AnvsShader.Uniform.Type.u_float
                    case '2f':
                        return AnvsShader.Uniform.Type.u_2f
                    case '3f':
                        return AnvsShader.Uniform.Type.u_3f
                    case '4f':
                        return AnvsShader.Uniform.Type.u_4f
                    case 'v2':
                        return AnvsShader.Uniform.Type.u_vec2
                    case 'v3':
                        return AnvsShader.Uniform.Type.u_vec3
                    case 'v4':
                        return AnvsShader.Uniform.Type.u_vec4
                    case '1iv':
                        return AnvsShader.Uniform.Type.u_1iv
                    case '2iv':
                        return AnvsShader.Uniform.Type.u_2iv
                    case '3iv':
                        return AnvsShader.Uniform.Type.u_3iv
                    case '4iv':
                        return AnvsShader.Uniform.Type.u_4iv
                    case '1fv':
                        return AnvsShader.Uniform.Type.u_1fv
                    case '2fv':
                        return AnvsShader.Uniform.Type.u_2fv
                    case '3fv':
                        return AnvsShader.Uniform.Type.u_3fv
                    case '4fv':
                        return AnvsShader.Uniform.Type.u_4fv
                    case 'm2':
                    case 'mat2':
                    case 'Matrix2fv':
                        return AnvsShader.Uniform.Type.u_mat2
                    case 'm3':
                    case 'mat3':
                    case 'Matrix3fv':
                        return AnvsShader.Uniform.Type.u_mat3 
                    case 'm4':
                    case 'mat4':
                    case 'Matrix4fv':
                        return AnvsShader.Uniform.Type.u_mat4
                    case 'c':
                        return AnvsShader.Uniform.Type.u_c
                    case 'iv1':
                        return AnvsShader.Uniform.Type.u_iv1
                    case 'iv':
                        return AnvsShader.Uniform.Type.u_iv
                    case 'fv1':
                        return AnvsShader.Uniform.Type.u_fv1
                    case 'fv':
                        return AnvsShader.Uniform.Type.u_fv
                    case 'v2v':
                        return AnvsShader.Uniform.Type.u_v2v
                    case 'v3v':
                        return AnvsShader.Uniform.Type.u_v3v
                    case 'v4v':
                        return AnvsShader.Uniform.Type.u_v4v;
                    default:
                        return AnvsShader.Uniform.Type.u_any;
                }
            }
        }
    }
}

export interface RanvsCustomShader extends Omit<AnvsCustomShader, "uniforms"> {
    uniforms?: Ranvs.Maybe.PoolOrOnceOrGet<RanvsShader.UniformGroup>,
}

export interface RanvsPixiFilter extends Omit<AnvsPixiFilter, "uniforms"> {
    uniforms?: Ranvs.Maybe.PoolOrOnceOrGet<RanvsShader.UniformGroup>,
}

export type RanvsShader = RanvsCustomShader | RanvsPixiFilter;

export namespace RanvsShader {

    export interface Uniform {
        type: AnvsShader.Uniform.Type,
        value: Ranvs.Maybe.PoolOrOnceOrGet<any>
    }

    export namespace Uniform {
        export function float(value: RanvsNumber): NumberUniform {
            return {type: AnvsShader.Uniform.Type.u_float, value}
        }
    }

    export interface NumberUniform extends Omit<AnvsShader.NumberUniform, "value"> {
        value: RanvsNumber,
    }

    export namespace NumberUniform { // Maybe include numbererd arrays too?
        export function isNumber(u: Uniform): u is NumberUniform {
            return u && typeof u === 'object' && (u.type == AnvsShader.Uniform.Type.u_int || u.type == AnvsShader.Uniform.Type.u_float);
        }
    }

    export type UniformGroup = {[key in string]: RanvsShader.Uniform}

    export function toAnvsShader(rShader: RanvsShader, generator: Ranvs.Generator): AnvsShader {

        if(rShader == null) return null; 

        const rUniforms = Ranvs.Maybe.Pool.pick(rShader.uniforms, generator) ?? {};
        
        const uniforms: AnvsShader.UniformGroup = {}
        Object.keys(rUniforms).forEach(uniformKey => {
            var rUniform = rUniforms[uniformKey];
            var value: any | number;

            if(NumberUniform.isNumber(rUniform)) {
                value = RanvsNumber.getNumber(rUniform.value, generator);
            } else {
                value = Ranvs.Maybe.Pool.pick(rUniform.value, generator);
            }

            uniforms[uniformKey] = {
                type: rUniform.type,
                value
            }
        });

        return {
            ...rShader, uniforms
        }
    }
}