import React from 'react';
import Helmet  from 'react-helmet'
import logo from '../images/anvs_logo.svg' 
import bog from '../images/bog_logo.png' 
import hmwrk from '../images/hmwrk logo.png' 
import { ContentCard } from './ContentCard';

import poo from '../images/poo.svg' 
import drip from '../images/pooUp.svg' 

import { AnvsShader, } from '../../../anvs/Anvs/Core';
import { AnvsReactLazy, } from '../../../anvs/AnvsReactLazy';

var fragment = `precision mediump float;

varying vec2 vTextureCoord;

uniform float resolution;
uniform vec4 filterArea;
uniform vec4 inputPixel;
uniform highp vec4 outputFrame;

uniform sampler2D uSampler;

uniform float uTime;

void main(){
	vec3 c;
	float l, z = sin(uTime / 1000.0) * 10.0 + 1000.0;
	for(int i=0;i<3;i++) {
        vec2 uv = vTextureCoord;
        vec2 p = uv;
		p -= 0.5;
		p.x *= outputFrame.z / outputFrame.w;
		z+= 3.;
		l=length(p)/1.;
		uv+=p/l*(z/12.);
        c[i]=.1/length(mod(uv, 1.0)-.05);
	}
	vec3 inp = (c/l) / 5.0;
    float lum = (inp.r+inp.g+inp.b)/3.0;
    gl_FragColor = vec4(lum, lum, lum, uTime);
}
`

export class HoloSpikeFilter {

    static AnvsShader(): AnvsShader {
        return {
            id: "_AnvsHoloSpikeSHader-",
            fragment, 
            uniforms:{uTime: AnvsShader.Uniform.float(2501047.838),},
            update: (u, f) => {
                u.uTime = AnvsShader.Uniform.float(u.uTime.value + f.deltaTime);
                return u;
            }
        }
    } 
}

export class BasePage extends React.Component<{title?: string, className?: string, children: React.ReactNode}, {}> {

    constructor(props) {
        super(props);
    }

    public render() {
        return <div key="BASE PAGE" className={this.props.className}>
            <Helmet
                key="helm"
                title={`Anvs.io ${this.props.title ? `(${this.props.title})` : ""}`}
                meta={[
                    { name: "viewport", content: "width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0"},
                    // { name:"apple-mobile-web-app-capable" , content:"yes"},
                    { name: 'description', content: 'Anvs.io - Layered canvas generation library for js.' },
                    { name: 'keywords', content: 'anvs, anus, ranvs, html5, canvas, webgl, pixijs, anvsio, generate, art, js, library, npm, react, layered, recursive' },
                ]}
                > 
                <link rel="preconnect" href="https://fonts.googleapis.com" />
                <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin="true"/>
                <link href="https://fonts.googleapis.com/css2?family=Montserrat:wght@300;500&family=Roboto+Mono:wght@400;700&display=swap" rel="stylesheet"/>
            </Helmet>

            <div key="page" style={{}}>
                
                <AnvsReactLazy.GL
                    size={{x: 100, y: 100}}
                    backgroundColor={0x444444FF}
                    style={{
                        position: "fixed",
                        top: "0",
                        height: "100vh",
                        width: "100vw",
                        imageRendering: "pixelated"
                    }}
                    updateFps={10}
                    antialias={false}
                    className={"stretch"}
                    dedicatedRenderer={true}
                    layer={{layers:[], image: poo, alpha: 0.2, shaders: [HoloSpikeFilter.AnvsShader()]}}
                />

                <div key="content" className="content" style={{ width: "95%"}}>
                    <div key="navcont" className='navContainer'>
                        <nav key="nav">
                            <a key="anvs" className='logo' href={"/"} ><img src={logo} /> </a>
                            <div key="links"> 
                                <li key="1"> <a key="doc" href="/docs"> Docs </a></li>
                                <li key="2"> <a key="ex" href="/examples"> Examples </a></li>
                                <li key="3"> <a key="gallery" href="/gallery"> Gallery </a></li>
                            </div>    
                        </nav>
                    </div>

                    <div key="contentinner" style={{minHeight:"0vh"}}>{
                        this.props.children
                    }</div>

                    <ContentCard key="footer" children={[
                        <a key="hmwrk" href="https://hmwrk.net" ><img style={{float:"right", position:"relative", right: "0", bottom: "0", maxHeight: "50px", padding: "10px"}} src={hmwrk} /></a>,
                        {type: "Note", content: <p>by <a href="https://bog.computer" ><img style={{display: "inline-block", maxHeight: "20px", }} src={bog} /></a></p>},
                        {type: "Note", content: <p>copyright 2022<a href="https://hmwrk.net" > hmwrk</a>, inc.</p>},
                    ]} />
                </div>

                <div key="poocont" style={{marginBottom: "30px"}} className='pooContainer'>
                    <img key="poo" className='poo' src={drip} />
                </div>

            </div>
            

            
        </div>
    }
}