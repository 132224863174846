import { AnvsVector, RanvsVector } from "./Primitive/Vector";
import { Ranvs } from "./Ranvs";
import { RanvsNumber } from "./Primitives"
import { AnvsSize, RanvsSize } from "./Size"

export enum AnvsMirrorMode { Horizontal, Vertical }

export type AnvsOffsetMode = "Pixel" | "Parent" | "Canvas"
export const AnvsOffsetMode = { Pixel: "Pixel", RelativeParent: "Parent", RelativeCanvas: "Canvas" }

export interface AnvsLayout<PVector extends AnvsVector.SimpleVector> {
    size?: AnvsSize<PVector>,
    offset?: PVector,
    anchor?: PVector,
    scale?: PVector,
    rotation?: number,
    offsetMode?: AnvsOffsetMode,
}

export namespace AnvsLayout {

    export function defaultLayout() { return {
        offset: AnvsVector.center(),
        scale: AnvsVector.one(),
        rotation: 0,
        size: AnvsSize.defaultSize(),
        anchor: AnvsVector.center(),
        offsetMode: AnvsOffsetMode.RelativeParent,
    } as Required<AnvsLayout<AnvsVector>> }
    
    export function zero() {
        return defaultMissing({anchor:0, offset: 0})
    }

    export function defaultMissing(layout: AnvsLayout<AnvsVector.SimpleVector>, overrides?: AnvsLayout<AnvsVector.SimpleVector>): Required<AnvsLayout<AnvsVector>>  {
        const def = AnvsLayout.defaultLayout();
        const size = layout?.size;
        
        return {
            scale: AnvsVector.SimpleVector.toAnvsVector(layout?.scale ?? (overrides?.scale ?? def.scale)),
            rotation: layout?.rotation ?? (overrides?.rotation ?? def.rotation),
            size: AnvsSize.defaultMissing(layout?.size, overrides?.size),
            anchor: AnvsVector.SimpleVector.toAnvsVector(layout?.anchor ?? (overrides?.anchor ?? def.anchor)),

            offset: AnvsVector.SimpleVector.toAnvsVector(layout?.offset ?? (overrides?.offset ?? def.offset)),
            offsetMode: layout?.offsetMode ?? (overrides?.offsetMode ?? def.offsetMode),
        }
    }

    export function equals(a: AnvsLayout<AnvsVector>, b: AnvsLayout<AnvsVector>): boolean {
        return AnvsSize.equals(a.size, b.size) && 
        AnvsVector.equals(a.offset, b.offset) && 
        AnvsVector.equals(a.anchor, b.anchor) && 
        AnvsVector.equals(a.scale, b.scale) &&
        a.rotation ===  b.rotation &&
        a.offsetMode ===  b.offsetMode;
    }

    export function copy(p: AnvsLayout<AnvsVector>): AnvsLayout<AnvsVector> {
        return {
            scale: AnvsVector.copy(p.scale),
            rotation: p.rotation,
            size: AnvsSize.copy(p.size),
            anchor: AnvsVector.copy(p.anchor),

            offset: AnvsVector.copy(p.offset),
            offsetMode: p.offsetMode,
        };
    }
}

export interface RanvsLayout {
    size?: RanvsSize,
    offset?: RanvsVector,
    anchor?: RanvsVector,
    scale?: RanvsVector,
    rotation?: RanvsNumber,
    offsetMode?: AnvsOffsetMode,
}

export namespace RanvsLayout { 

    export function toAnvsLayout(rLayout: Ranvs.Maybe.PoolOrOnceOrGet<RanvsLayout>,  generator: Ranvs.Generator): Required<AnvsLayout<AnvsVector>> {

        return Ranvs.Maybe.cache(rLayout, ()=>{ 
            const layout = Ranvs.Maybe.Pool.pickNoCache(rLayout, generator);
    
            if(layout == null) return AnvsLayout.defaultMissing({});
    
            const defaultLayout = AnvsLayout.defaultLayout();
    
            const anchor = RanvsVector.toAnvsVector(layout.anchor, defaultLayout.anchor, generator);
            const offset = RanvsVector.toAnvsVector(layout.offset, defaultLayout.offset, generator);
            const rotation = RanvsNumber.getNumber(layout.rotation, generator);
            const scale = RanvsVector.toAnvsVector(layout.scale, defaultLayout.scale, generator);
    
            const size = RanvsSize.toAnvsSize(layout.size, defaultLayout.size, generator);
    
            const offsetMode = layout.offsetMode;
    
            const final = AnvsLayout.defaultMissing({
                anchor, offset, rotation, scale, size, offsetMode
            });
    
            return final
        }, 
        generator.pass);
    }
}