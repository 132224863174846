import { AnvsVector, RanvsVector } from "./Primitive/Vector";
import { Ranvs } from "./Ranvs";

export type AnvsRelativeSize = "parent" | "canvas" | "image";
export type AnvsSize<PVector extends AnvsVector.SimpleVector> = AnvsVector.SimpleVector | AnvsRelativeSize; // TODO, maybe make parent, canvas, and image have variance

export namespace AnvsSize {

    export function defaultSize(): AnvsSize<AnvsVector.SimpleVector> {
        return "image";
    }

    export function defaultMissing(size: AnvsSize<AnvsVector.SimpleVector>, override?: AnvsSize<AnvsVector.SimpleVector>): AnvsSize<AnvsVector> {
        const def = AnvsSize.defaultSize();
        const vectorOrString = (s: AnvsSize<AnvsVector.SimpleVector>) => {
            return s == "parent" || s == "canvas" || s == "image" ? s : AnvsVector.SimpleVector.toAnvsVector(s);
        } 
        return vectorOrString(size) ?? vectorOrString(override) ?? vectorOrString(def);
    }
    
    export function equals(a: AnvsSize<AnvsVector>, b: AnvsSize<AnvsVector>): boolean {
        return (
            (a == "parent" && b == "parent") || 
            (a == "canvas" && b == "canvas") || 
            (a == "image" && b == "image") || 
            (
                (a != "parent" && b != "parent") && 
                (a != "canvas" && b != "canvas") && 
                (a != "image" && b != "image") && 
                AnvsVector.equals(a, b)
            )
        );
    }

    export function copy(s: AnvsSize<AnvsVector>): AnvsSize<AnvsVector> {
        return s == "parent" || s == "canvas" || s == "image" ? s : AnvsVector.copy(s);
    }

    export function isRelativeSize(object: any): object is AnvsRelativeSize {
        return object == 'parent' || object == 'canvas' || object == 'image';
    }
}


export type RanvsSize = Ranvs.Maybe.PoolOrOnceOrGet<RanvsVector.SimpleVector | AnvsRelativeSize>;

export namespace RanvsSize {
    export function toAnvsSize(r: RanvsSize, defaultSize: AnvsSize<AnvsVector.SimpleVector>, generator: Ranvs.Generator): AnvsSize<AnvsVector> {
        const v = Ranvs.Maybe.Pool.pick(r, generator);

        if(!AnvsSize.isRelativeSize(v)) {
            return RanvsVector.toAnvsVector(v, AnvsVector.zero(), generator);
        } else {
            return AnvsSize.defaultMissing(v);
        }
    }
}
