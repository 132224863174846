import { AnvsImage, RanvsImage } from "./Image";
import { Ranvs } from "./Ranvs";

/**
* @Note - Masking only works in GL mode.
* @TODO - Figure out masking in Canvas mode.
*/
export interface AnvsMask<SpecificSimpleImage extends AnvsImage.SimpleImage> {
    mode?: AnvsMask.MapMode,
    image: SpecificSimpleImage
}

export namespace AnvsMask {
    export enum MapMode { Default, Alpha }

    export type SimpleMask = AnvsMask<AnvsImage.SimpleImage> | AnvsImage.SimpleImage;

    function isMask(m: SimpleMask): m is AnvsMask<AnvsImage.SimpleImage> {
        return m && typeof m === 'object' && 'image' in m;
    }

    export namespace SimpleMask {

        // FIXME: Define a filled anvs mask so there's a notion of one that's filled vs not
        export function toAnvsMask(simple: SimpleMask): AnvsMask<AnvsImage> {

            const defaultMode = AnvsMask.MapMode.Default;

            if(isMask(simple)) return {image: AnvsImage.SimpleImage.toAnvsImage(simple.image), mode: simple.mode ?? defaultMode};
            else return {image: AnvsImage.SimpleImage.toAnvsImage(simple), mode: defaultMode};
        }
    }

}

export interface RanvsMask<SpecificSimpleImage extends RanvsImage.SimpleImage> {
    mode?: AnvsMask.MapMode,
    image: Ranvs.Maybe.PoolOrOnceOrGet<SpecificSimpleImage>
}

export namespace RanvsMask {
    export type SimpleMask = RanvsMask<RanvsImage.SimpleImage> | RanvsImage.SimpleImage;

    function isMask(m: SimpleMask): m is RanvsMask<RanvsImage.SimpleImage> {
        return m && typeof m === 'object' && 'image' in m;
    }

    export function toMask(mask: Ranvs.Maybe.PoolOrOnceOrGet<SimpleMask>,  generator: Ranvs.Generator): AnvsMask.SimpleMask {
        mask = Ranvs.Maybe.Pool.pick(mask, generator);

        if(mask == null) return null;
        if(isMask(mask)) {
            var image = RanvsImage.toImage(mask.image, generator);
            return {image, mode: mask.mode}
        } else return {image: RanvsImage.toImage(mask, generator)};
    }
}
