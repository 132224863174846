
import { isString } from "../../Help";
import { AnvsMask, RanvsMask, AnvsImage, RanvsImage, AnvsShader, AnvsUpdatable, AnvsVector, Ranvs, RanvsBoolean, RanvsNumber, RanvsShader, AnvsLayout, AnvsMirrorMode, RanvsLayout } from "../Core";
import { AnvsText, RanvsText } from "./Text";

export type AnvsLayer = AnvsLayer.GroupLayer | AnvsLayer.ImageLayer | AnvsLayer.TextLayer;

export namespace AnvsLayer {

    export type GroupLayer = Abstract.GroupLayer<AnvsVector.SimpleVector, AnvsImage.SimpleImage>;
    export type TextLayer = Abstract.TextLayer<AnvsVector.SimpleVector, AnvsText.SimpleText>;
    export type ImageLayer = Abstract.ImageLayer<AnvsVector.SimpleVector, AnvsImage.SimpleImage>
    
    export namespace Abstract {

        export interface Layer<V extends AnvsVector.SimpleVector, U> {
            layout?: AnvsLayout<V>, // allow the vector to be a bit more simple to type
            mirror?: AnvsMirrorMode,
            visible?: boolean,
            alpha?: number,
            shaders?: AnvsShader[],
            update?: AnvsUpdatable<U>
        }

        export interface GroupLayer<V extends AnvsVector.SimpleVector, I extends AnvsImage.SimpleImage> extends Layer<V, GroupLayer<V, I>> {
            layers: (Layer<V, AnvsLayer> | string)[],
            image?:  I,
            mask?: AnvsMask.SimpleMask,
        }

        export interface ImageLayer<V extends AnvsVector.SimpleVector, I extends AnvsImage.SimpleImage> extends Layer<V, ImageLayer<V, I>> {
            image: I,
        }
 
        export interface TextLayer<V extends AnvsVector.SimpleVector, T extends AnvsText.SimpleText> extends Layer<V, TextLayer<V, T>> {
            text: T,
        }

    }

    // Helpers

    export function isGroupLayer<LT extends Abstract.Layer<AnvsVector.SimpleVector, AnvsLayer>, IT extends AnvsImage>(object: RanvsLayer | AnvsLayer): object is (GroupLayer | RanvsLayer.GroupLayer) {
        return object && typeof object !== 'string' && typeof object === 'object' && 'layers' in object;
    }

    export function isImageLayerOrString(object: RanvsLayer | AnvsLayer | string): object is (string | ImageLayer | RanvsLayer.ImageLayer) {
        return object && (typeof object === 'string' || (typeof object === 'object' && 'image' in object && !('layers' in object)));
    }

    export function isImageLayer(object: RanvsLayer | AnvsLayer): object is (ImageLayer | RanvsLayer.ImageLayer) {
        return object && (typeof object === 'object' && 'image' in object && !('layers' in object));
    }

    export function isTextLayer(object: RanvsLayer | AnvsLayer): object is (TextLayer | RanvsLayer.TextLayer) {
        return object && (typeof object === 'object' && 'text' in object && !('layers' in object));
    }

}

export type RanvsLayer = RanvsLayer.GroupLayer | RanvsLayer.ImageLayer | RanvsLayer.TextLayer;

export namespace RanvsLayer {

    export type GroupLayer = Ranvs.Maybe.PoolOrOnceOrGet<Abstract.GroupLayer>;
    export type TextLayer = Ranvs.Maybe.PoolOrOnceOrGet<Abstract.TextLayer>;
    export type ImageLayer = Ranvs.Maybe.PoolOrOnceOrGet<Abstract.ImageLayer>;

    export namespace ImageLayer {

        export function toAnvsImageLayer(rLayer: AnvsLayer.ImageLayer | RanvsLayer.ImageLayer | string, generator: Ranvs.Generator): Required<AnvsLayer.ImageLayer> {

            if(isString(rLayer)) {
                const i: AnvsLayer.ImageLayer = {image: {src: rLayer}};
                var base = Abstract.toLayer(i, generator);
                return {...base, image: i.image};
            } else {
                return Ranvs.Maybe.cache(rLayer, ()=>{ 
                    var layer = Ranvs.Maybe.Pool.pickNoCache(rLayer, generator);
                    var image = RanvsImage.toImage(layer.image, generator);
                    var base = Abstract.toLayer(layer, generator);
                    return  {...base, image};
                }, 
                generator.pass);
            }
        }
    }

    export namespace TextLayer {

        export function toAnvsTextLayer(rLayer: AnvsLayer.TextLayer | RanvsLayer.TextLayer,  generator: Ranvs.Generator): Required<AnvsLayer.TextLayer> {

            return Ranvs.Maybe.cache(rLayer, ()=>{ 
                const layer = Ranvs.Maybe.Pool.pickNoCache(rLayer, generator);
                
                var text = RanvsText.toText(layer.text, generator);            
                var base = Abstract.toLayer(layer, generator);

                return  {...base, text}
            }, 
            generator.pass);
        }
    }

    export namespace GroupLayer {
        
        export function toAnvsGroupLayer(rGroup: AnvsLayer.GroupLayer | RanvsLayer.GroupLayer,  generator: Ranvs.Generator): Required<Omit<AnvsLayer.GroupLayer, "layers">> {
            
            return Ranvs.Maybe.cache(rGroup, ()=>{ 

                const group = Ranvs.Maybe.Pool.pickNoCache(rGroup, generator);
                
                var image = RanvsImage.toImage(group.image, generator);
                var mask = RanvsMask.toMask(
                    Ranvs.Maybe.Pool.pick(group.mask, generator), 
                    generator
                );
                
                var base = Abstract.toLayer(group, generator);

                return {...base, image, mask}
            }, 
            generator.pass);
        }

    }
    
    export namespace Abstract {

        export function toLayer<L>(rLayer: AnvsLayer.Abstract.Layer<AnvsVector.SimpleVector, L> | Layer<L>,  generator: Ranvs.Generator): Required<AnvsLayer.Abstract.Layer<AnvsVector, L>> {

            var layer = Ranvs.Maybe.Pool.pickNoCache(rLayer, generator);
            
            var layout = RanvsLayout.toAnvsLayout(layer.layout, generator);
            var mirror = Ranvs.Maybe.Pool.pick(layer.mirror, generator);

            var update = Ranvs.Maybe.Pool.pick(layer.update, generator);

            var visible = Ranvs.Maybe.Pool.pick(layer.visible, generator) ?? true;
            var alpha = RanvsNumber.getNumber(layer.alpha, generator) ?? 1;

            var shaders: AnvsShader[] = []

            var rShaders = Ranvs.Maybe.Pool.pick(layer.shaders, generator);

            rShaders?.forEach(rShader => {
                rShader =  Ranvs.Maybe.Pool.pick(rShader, generator);
                var aShader = RanvsShader.toAnvsShader(rShader, generator);

                if(aShader) shaders.push(aShader);
            });
            
            return { layout, alpha, mirror, update, visible, shaders }; // don't cache here cuz that'll break setting things like image!!
        }

        export interface Layer <U> {
            layout?: Ranvs.Maybe.PoolOrOnceOrGet<RanvsLayout>, // allow the vector to be a bit more simple to type
            mirror?: Ranvs.Maybe.PoolOrOnceOrGet<AnvsMirrorMode>,
            visible?: RanvsBoolean,
            alpha?: RanvsNumber,
            shaders?: Ranvs.Maybe.PoolOrOnceOrGet<Ranvs.Maybe.PoolOrOnceOrGet<RanvsShader>[]>,
            update?: Ranvs.Maybe.PoolOrOnceOrGet<AnvsUpdatable<U>>
        }

        export interface GroupLayer extends Layer<AnvsLayer.GroupLayer> {
            layers: Ranvs.Maybe.PoolOrOnceOrGet<(RanvsLayer | string)[]>,
            image?: Ranvs.Maybe.PoolOrOnceOrGet<RanvsImage.SimpleImage>,
            mask?: Ranvs.Maybe.PoolOrOnceOrGet<RanvsMask.SimpleMask>,
        }

        export interface ImageLayer extends Layer<AnvsLayer.ImageLayer> {
            image: Ranvs.Maybe.PoolOrOnceOrGet<RanvsImage.SimpleImage>,
        }

        export interface TextLayer extends Layer <AnvsLayer.TextLayer>{
            text: Ranvs.Maybe.PoolOrOnceOrGet<RanvsText.SimpleText>,
        }

    }

}
