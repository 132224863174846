import { prototype } from "events";
import { RanvsNumber } from "../Core";

export namespace Ranvs {

    export interface GeneratorFunction {
        generate: (seed: number) => number;
    }

    export namespace GeneratorFunction {

        export class Tausworthe implements GeneratorFunction {

            static generate = (seed: number): number => {
                seed ^= seed >> 13;
                seed ^= seed << 18;
                seed & 0x7fffffff;
                return seed;
            }

            generate(seed: number) {
                return Tausworthe.generate(seed);
            }
        }
        
        export class LinearCongruential implements GeneratorFunction {

            private static A = 1664525
            private static C = 1013904223
            private static M = 4294967296

            pass: number;
            seed: number;

            a: number;
            c: number;
            m: number;

            static generate(seed: number, a: number = LinearCongruential.A, c: number = LinearCongruential.C, m: number = LinearCongruential.M): number {
                seed = (a * seed + c) % m;
                return seed;
            }

            generate = (): number => {
                this.seed = LinearCongruential.generate(this.seed, this.a, this.c, this.m);
                return this.seed;
            }

            constructor(seed: number, a: number = LinearCongruential.A, c: number = LinearCongruential.C, m: number = LinearCongruential.M) {
                this.seed = seed;
                this.a = a;
                this.c = c;
                this.m = m;
            }
        }

    }

    export class Generator {

        private _pass = 0;
        get pass(): number { return this._pass; };

        private _seed = 0;
        get seed(): number { return this._seed; };

        private _currentGen: number;

        reseed(seed: number): Generator {
            this._pass++;
            this._currentGen = seed;
            this._seed = seed;
            return this;
        };

        generate(): number { return this._currentGen = this.generatorFunction.generate(this._currentGen); };
        generatePercent(): number {this._currentGen = this.generatorFunction.generate(this._currentGen); return (Math.abs(this._currentGen) % 100) / 100 };

        private generatorFunction: GeneratorFunction;
        constructor(generatorFunction: GeneratorFunction) {
            this.generatorFunction = generatorFunction;
        }

    }

    export namespace Generator {
        export const Default: Generator = new Generator(new GeneratorFunction.Tausworthe());
    }

    export interface Cache<T> {
        // NOTE: Without this everything caches. Wihtout it, it is much much more confusing to handle while building anvs images. 
        // It's nicer to expicitly mark which exact pieces you want cached imo. Maybe I'll change my mind later.
        once?: boolean, 
        _ranvs_cache?: {cached?: T, pass?: number}
    }

    export interface Pool<T> extends Cache<T> {
        pool: T[],
        choose?: Ranvs.Maybe.PoolOrOnceOrGet<number>,
    }

    export interface WeightedPool<T> extends Cache<T> {
        weightedPool: {item: T, weight: number}[],
        choose?: Ranvs.Maybe.PoolOrOnceOrGet<number>,
    }

    export interface Get<T, O extends (any | RanvsNumber)> extends Cache<T> {
        get: (random: number, selected?: O | number) => T,
        options?: Ranvs.Maybe.PoolOrOnceOrGet<O | number>,
    }

    export namespace Maybe {

        // a maybe pool is a maybe once, maybe T, or maybe a recursice pool of those things 
        export type PoolOrOnceOrGet<T> = T | Ranvs.Pool<Maybe.PoolOrOnceOrGet<T>> | Ranvs.WeightedPool<Maybe.PoolOrOnceOrGet<T>> | Ranvs.Get<Maybe.PoolOrOnceOrGet<T>, any>

        export function cache<T>(maybeOnce: any, computeFinalValue: () => any, pass: number): any {

            if(!isOnce(maybeOnce)) return computeFinalValue();

            if(maybeOnce._ranvs_cache == null) {
                maybeOnce._ranvs_cache = {pass};
            }

            if(pass != maybeOnce._ranvs_cache.pass) {
                maybeOnce._ranvs_cache.pass = pass; 
                maybeOnce._ranvs_cache.cached = undefined; 
            }

            if(maybeOnce._ranvs_cache.cached === undefined) { 
                maybeOnce._ranvs_cache.cached = computeFinalValue(); 
            }

            return maybeOnce._ranvs_cache.cached;
        }

        export namespace Pool {
            const mod = (n: number, m: number) => {
                return ((n % m) + m) % m;
            }

            // used when there needs an extra storing step, (like a vector)
            function pickAndMaybeCache<T>(pooled: Maybe.PoolOrOnceOrGet<T>, generator: Ranvs.Generator, skipCache: boolean): T {

                var value = () => {

                    if(isWeightedPool(pooled)){
    
                        // var newPool: T[] = []
                        // pooled.weightedPool.forEach(weighted => {
                        //     if(weighted.weight > 0) {
                        //         var arr: T[] = new Array(weighted.weight).fill(weighted.item);
                        //         newPool = newPool.concat(arr);
                        //     }
                        // });
    
                        // pooled = {pool: newPool, ...pooled};

                        let index: number;
                        if(pooled.choose != null) {
                            const choose = pick(pooled.choose, generator)
                            index = mod(choose, pooled.weightedPool.length);
                        } else {
                            var weights: number[] = [];
                        
                            for (index = 0; index < pooled.weightedPool.length; index++)
                                weights[index] = pooled.weightedPool[index].weight + (weights[index - 1] || 0);
                            
                            var random =  Math.abs((generator.generate() % 100) / 100) * weights[weights.length - 1];
                            
                            for (index = 0; index < weights.length; index++)
                                if (weights[index] > random)
                                    break;
                        }
                        return pick(pooled.weightedPool[index].item, generator);
    
                    } else if(isPool(pooled)) {

                        const choose = pick(pooled.choose, generator) ?? Math.abs(generator.generate()) 
                        const index = mod(choose, pooled.pool.length);
                        var item = pooled.pool[index];

                        return pick(item, generator);
    
                    } else if(isGet(pooled)) {
    
                        var selectedOption = pick(pooled.options, generator);

                        if(RanvsNumber.isRanvsNumber(selectedOption)) {
                            selectedOption = RanvsNumber.getNumber(selectedOption, generator);
                        }

                        return pick(
                            pooled.get(generator.generate(), selectedOption), 
                            generator
                        );
    
                    } else {
                        return pooled;
                    }
                }

                // FIXME: Gross. skipCache is used for other structures that handle their own cache.
                // EX: A RanvsNumber like Range would get cached as a {range: []} and not actually calculate a value
                //     because this would just store value() which doesn't know how to handle the number. And when that happens, the 
                //     cache is taken for this pass which means the actual number will get discarded.  
                if(!skipCache && isOnce(pooled)) {
                    return cache(pooled, () => { return value(); }, generator.pass);
                } else {
                    return value();
                }
            }

            // used when there needs an extra storing step, (like a vector)
            export function pick<T>(pooled: Maybe.PoolOrOnceOrGet<T>, generator: Ranvs.Generator): T {
                return pickAndMaybeCache(pooled, generator, false);
            }

            // used when there needs an extra storing step, (like a vector)
            export function pickNoCache<T>(pooled: Maybe.PoolOrOnceOrGet<T>, generator: Ranvs.Generator): T {
                return pickAndMaybeCache(pooled, generator, true);
            }

        }

    }
    export function isOnce<T>(object: any): object is Cache<T> {
        return object && typeof object === 'object' && 'once' in object && object.once == true;
    }

    export function isGet<T, O>(object: any): object is Get<T, O> {
        return object && typeof object === 'object' && 'get' in object;
    }

    export function isPool<T>(object: any): object is Pool<T> {
        return object && typeof object === 'object' && 'pool' in object;
    }

    export function isWeightedPool<T>(object: any): object is WeightedPool<T> {
        return object && typeof object === 'object' && 'weightedPool' in object;
    }

    export function isRanvs<T>(object: any): object is (Ranvs.Pool<T> | Ranvs.WeightedPool<T> | Ranvs.Get<T, any>) {
        return Ranvs.isPool(object) || Ranvs.isWeightedPool(object) || Ranvs.isGet(object);
    }
    

}